import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { css } from '@emotion/core';
import userIcon from '@fortawesome/fontawesome-free/svgs/solid/user.svg';

import { Button, ImageText } from '@myungsoo/components';
import { BaseLayout, Title } from '@myungsoo/base/components';
import { useAuth } from '@myungsoo/base/hooks';
import { mediaQueries as mq } from '@myungsoo/base/styles';

import titleImage from '../images/title.svg';

const AdminLayout = ({ basepath = '/admin', children, ...restProps }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `,
  );

  const { initialized, user, permitted, signOut } = useAuth(
    '#firebaseui-auth-container',
  );

  const [showProfile, setShowProfile] = React.useState(false);

  return (
    <BaseLayout
      css={css`
        height: 100%;
      `}
      loading={!initialized}
      showHeader={permitted}
      menuItems={[
        { key: 'about', to: `${basepath}/about`, children: 'About' },
        { key: 'reel', to: `${basepath}/reel`, children: 'Reel' },
        { key: 'news', to: `${basepath}/news`, children: 'News' },
        { key: 'contact', to: `${basepath}/contact`, children: 'Contact' },
      ]}
      renderTitle={(props) => (
        <Title {...props} render={Link} to={basepath}>
          {({ hover }) => (
            <>
              <ImageText src={titleImage} useMask={hover}>
                {site.siteMetadata.title}
              </ImageText>
              <span
                css={css`
                  margin-left: 0.5rem;
                  font-size: 0.5rem;
                  text-transform: uppercase;
                `}
              >
                Admin
              </span>
            </>
          )}
        </Title>
      )}
      renderPostHeaderContent={() => {
        if (!user) {
          return null;
        }

        return (
          <div
            css={css`
              order: 2;
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0 1rem;

              @media ${mq[0]} {
                order: 4;
              }
            `}
          >
            <img
              css={css`
                width: 2rem;
                height: 2rem;
                border-radius: 50%;
                border: 1px solid white;
                background: white;
                cursor: pointer;
              `}
              src={user.photoURL || userIcon}
              alt=""
              onClick={() => setShowProfile(!showProfile)}
            />
            <div
              css={[
                css`
                  display: none;
                  position: absolute;
                  top: 100%;
                  right: 0;
                  z-index: 10;

                  padding: 0.5rem;

                  background: rgba(255, 255, 255, 0.1);
                `,
                showProfile &&
                  css`
                    display: block;
                  `,
              ]}
            >
              <div
                css={css`
                  display: flex;
                  flex-flow: nowrap;
                  align-items: center;
                  margin-bottom: 0.5rem;
                `}
              >
                <img
                  css={css`
                    width: 3rem;
                    height: 3rem;
                    border-radius: 50%;
                    border: 1px solid white;
                    background: white;
                  `}
                  src={user.photoURL || userIcon}
                  alt=""
                />

                <div
                  css={css`
                    padding: 0.5rem;
                  `}
                >
                  <div>{user.displayName}</div>
                  <div
                    css={css`
                      font-size: 0.75rem;
                    `}
                  >
                    {user.email}
                  </div>
                </div>
              </div>
              <Button
                css={css`
                  width: 100%;
                `}
                onClick={signOut}
              >
                Sign Out
              </Button>
            </div>
          </div>
        );
      }}
      {...restProps}
    >
      {!user && (
        <div
          css={css`
            height: 100%;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: stretch;
          `}
        >
          <div id="firebaseui-auth-container" />
        </div>
      )}

      {user && !permitted && (
        <div
          css={css`
            height: 100%;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
          `}
        >
          <div>Permission Denied</div>
          <Button onClick={signOut}>Sign Out</Button>
        </div>
      )}

      {permitted && children}
    </BaseLayout>
  );
};

export default AdminLayout;
